@import 'assets/variables';

.primary-view {
  background: rgb(244, 244, 244);
  width: 125px;
  min-width: 175px;
  box-shadow: 4px 0 5px -5px #333;

  @media (min-width: 600px) {
    min-width: 183px;
  }

  .navigation-link {
    text-decoration: none;
    cursor: pointer;
    color: $omniva-color-brand-primary;
  }

  .MuiListItem-root.Mui-selected {
    background-color: white;
    color: $omniva-color-black;
  }

  .MuiTypography-root {
    font-family: $omniva-headings-font;
    font-size: 0.875rem;
  }
}
