@import 'assets/variables';

.cas-redirect-view {
  height: 100vh;
  overflow-y: auto;

  &__container {
    min-height: 100vh;

    &__client {
      flex: 1;
    }
  }

  &__banner {
    color: $omniva-color-brand-primary;

    img {
      height: 3rem;
    }
  }
}
