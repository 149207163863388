@import 'assets/variables';

:root {
  --text-black: black;
  --text-red: #d45452;
}

.create-subscription-form {
  font-family: $omniva-headings-font;
  font-weight: 500;
  font-size: 14px;
  width: 780px;

  .button-row {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    text-align: center;
    float: right;
  }

  .field {
    background-color: $omniva-color-white;
    float: right;
    width: 380px;
    height: 38px;
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }

  .form-control {
    float: right;
    width: 380px;
  }

  .dates {
    display: flex;
    float: right;
    justify-content: space-between;

    .left-date {
      margin-right: 20px;
    }

    .date-left {
      width: 380px;
    }
  }

  .MuiInputBase-input {
    height: 4px;
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }

  .Mui-disabled {
    color: var(--text-black) !important;
    -webkit-text-fill-color:var(--text-black) !important;
  }

  .MuiInputLabel-root {
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }

  .hidden {
    display: none;
  }

  .bottom-bar {
    height: 84px;
    display: flex;
  }

  .required-label {
    color:red;
  }

  .dates .left-date .MuiFormHelperText-root.Mui-error {
    max-width: 260px;
    margin-right: -90px;
    margin-left: 0;
  }

  .dates .right-date .MuiFormHelperText-root.Mui-error {
    max-width: 150px;
  }

  .address-box {
    width: 380px;
    float: right;

    .MuiOutlinedInput-root {
      padding: 5px 5px 5px 10px;

      textarea {
        overflow-y: scroll !important;
        height: 40px !important;
      }
    }

    .top-space {
      position: relative;
      top: -30px;
    }
  }

  .periods {
    display: flex;
    float: right;
    justify-content: space-between;
    max-width: 380px;
  }

  .number {
    margin-right: 20px;
  }

  .narrow-field {
    width: 180px;
  }

  .per-address-box__option, .per-address-box__single-value {
    white-space: pre-wrap !important;
  }

  .per-address-box__single-value {
    overflow-y: scroll;
    height: 45px !important;
  }

  .per-address-box__value-container {
    height: 45px !important;
  }

  .red-text {
    color: var(--text-red);
  }

  .padding-bottom-larger {
    padding-bottom: 2rem !important;
  }
}

.complex-flex-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
