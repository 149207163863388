@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.table-footer .grid-page-details {
  vertical-align: middle;
  justify-content: center;
}

.table-footer .custom-pagination {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-family: $omniva-headings-font;
}

.table-footer .page-select {
  color: $omniva-color-black;
  float: right;
  width: 100px;
  height: 38px;
  font-family: $omniva-headings-font;
  font-size: 14px;
  font-weight: 500;
}

.MuiPaginationItem-icon {
  color: $omniva-color-brand-primary;
}

.table-footer {
  justify-content: flex-end;
  padding: 0 24px 0 24px;
}

.pagination-item {
  background-color: white;
}

.pagination-item.Mui-selected {
  color: black;
  background-color: white;
  border-width: thin;
  border-radius: 5px;
  border-color: black;
  border-style: solid;
}

.pagination-item.MuiButtonBase-root {
  color: $omniva-color-brand-primary;
  font-family: $omniva-headings-font;
  font-size: 14px;
  font-weight: 500;
}

.pagination-item.MuiButtonBase-root.Mui-selected {
  color: black;
}
