@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.subscription-list-view {
  overflow-y: auto;
  width: 100%;
  padding-bottom: 1rem;

  .title-row {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    text-align: center;

    h3 {
      vertical-align: middle;
      text-align: center;
      font-weight: 500;
      font-size: 28px;
    }

    .button-row {
      display: flex;
      justify-content: flex-end;
      vertical-align: middle;
      text-align: center;
      width: 350px;
    }
  }
}
