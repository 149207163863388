@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.login-view {
  background-color: $omniva-color-white;
  height: 100vh;
  overflow-y: auto;

  &__container {
    min-height: 100vh;

    &__welcome_text{
      width: 70%;
      margin-block-start: 35%;
      text-align: left;
    }

    &__left{
      background-color: $omniva-color-brand-primary;

      &__logo {
        position: absolute;
        left: 60px;
        bottom: 60px;
        filter: invert(99%) sepia(96%) saturate(15%) hue-rotate(216deg) brightness(104%) contrast(100%);

        &__slogan {
          margin-top: 45px;
          width: 50%;
          font-size: 1.125 * $font-size-base;
          line-height: 138%;
          letter-spacing: 0.2em;
          text-transform: uppercase;
        }
      }
    }

    &__right{
      display: flex;
      flex-direction: column;
      text-align: center;

      &__top {
        width: 70%;
        margin-top: 40px;
      }

      &__center {
        width: 70%;
        margin-block-start: 30%;
      }

      .btn-login {
        background-color: $omniva-color-button;
        border:none;
        padding: 0.5rem 1.25rem;
        color: $omniva-color-black;
        border-radius: 50px;
        font-weight: 400;
        position: relative;
      }

      .loading-spinner {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  & .validation {
    font-size: $font-size-sm;
    color: $omniva-color-red;
  }

  .hidden {
    visibility: hidden;
  }

}
