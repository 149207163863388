@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.subscription-detail-view {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;

  .title-row {
    display: flex;
    vertical-align: middle;
    text-align: center;
    padding-top: 15px;

    .left-arrow {
      filter: invert(61%) sepia(93%) saturate(4798%) hue-rotate(3deg) brightness(102%) contrast(112%);
      padding-left: 1px;
      padding-bottom: 2px;
      padding-top: 6px;
    }

    h3 {
      padding-left: 22px;
      vertical-align: middle;
      text-align: center;
      font-weight: 500;
      font-size: 28px;
    }
  }
}