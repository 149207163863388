// Colors
$omniva-color-brand-primary: #ff6600; // clickable
$omniva-color-brand-secondary: #f36b21;
$omniva-color-brand-third: #e31b23;
$omniva-color-brand-accent: #e20077; // highlight

$omniva-color-red: #e31b22; // errors
$omniva-color-yellow: #efbe10; // warnings
$omniva-color-green: #26ab4b; // success
$omniva-color-blue: #0e79B2; // information

$omniva-color-black: #000000; // text
$omniva-color-gray-darkest: #4f4f4f;
$omniva-color-gray-dark: #58595B;
$omniva-color-gray: #a7a9ac;
$omniva-color-gray-light: #ededed;
$omniva-color-gray-lightest: #f2f1f0; // disabled
$omniva-color-white-dark: #fafafa; // background
$omniva-color-white: #ffffff;

$omniva-color-button: #FFBC01;

// Fonts
$omniva-headings-font: "TT Wellingtons", sans-serif;
$omniva-headings-line-height: 1.25;
$omniva-line-height: 1.5;

$omniva-input-font: "TT Wellingtons", sans-serif;

// Utilities
$phoenix-global-gutter: 1rem;
$phoenix-global-radius: 4px;
$phoenix-global-shadow: 1px 2px 4px 0 $omniva-color-gray;
