@import 'variables';
@import 'mixins';

@mixin phoenix-TTWellingtons-font-woff-source($font-type) {
  @include phoenix-font-woff-source('assets/fonts', 'TTWellingtons', $font-type);
}

// Bold
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Bold');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Bold');
  font-weight: bold;
  font-style: normal;
}

// BoldItalic
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('BoldItalic');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('BoldItalic');
  font-weight: bold;
  font-style: italic;
}

// Light
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Light');
  font-weight: 300;
  font-style: normal;
}

// Italic
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Italic');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Italic');
  font-weight: normal;
}

// Light Italic
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('LightItalic');
  font-weight: 300;
  font-style: italic;
}

// Regular
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Regular');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TT Wellingtons';
  @include phoenix-TTWellingtons-font-woff-source('Regular');
  font-weight: normal;
  font-style: normal;
}