@import 'assets/variables';

.language-select {
  input[type=radio] {
    display: none;
  }

  label {
    margin-right: 1.25rem;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
  }

  input[type=radio]:checked + label {
    color: $omniva-color-brand-primary;
  }
}