@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.breadcrumb-content {
  .breadcrumb-text {
    color: $omniva-color-brand-primary;
    font-family: $omniva-headings-font;
  }

  .breadcrumb-text-last {
    color: $omniva-color-gray-dark;
    font-family: $omniva-headings-font;
  }
}