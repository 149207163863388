@import 'assets/variables';

.language-select {
  display: flex;
  align-items: center;
  gap: 2px;

  .logo {
    fill: $omniva-color-brand-primary;
  }

  #languageSelect {
    width: 100%;
  }

  .select {
    font-family: inherit;
    padding-top: 3px;
    border-width: medium;
    border-color: white;
  }

  #dropdown-menu {
    min-width: 0;
  }
}