@import 'variables';

// Colors
$primary:   $omniva-color-brand-primary;
$secondary: $omniva-color-brand-secondary;

$success:   $omniva-color-brand-primary;
$info:      $omniva-color-blue;
$warning:   $omniva-color-yellow;
$danger:    $omniva-color-red;

$light:     $omniva-color-white-dark;
$dark:      $omniva-color-gray-dark;

$blue:      $omniva-color-blue;
$red:       $omniva-color-red;
$yellow:    $omniva-color-yellow;
$green:     $omniva-color-green;

$black:     $omniva-color-black;
$white:     $omniva-color-white;

$input-group-addon-bg:     $omniva-color-white;
$tooltip-bg:               $omniva-color-brand-accent;
$card-cap-bg:              $omniva-color-brand-primary;
$input-focus-border-color: $omniva-color-brand-primary;

// Fonts
$headings-font-family:   $omniva-headings-font;
$headings-line-height:   $omniva-headings-line-height;
$headings-margin-bottom: 0;
$line-height-base:       $omniva-line-height;
$btn-font-weight:        700;

// Layout
$border-radius: $phoenix-global-radius;
$input-btn-border-width: 2px;

$grid-gutter-width: 0px !default;

$card-spacer-y: 5px !default;
$card-spacer-x: 10px !default;
