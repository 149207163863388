@import 'variables';
@import 'font-ttwellingtons';

// Logo

.type-logo {
  font-family: $omniva-input-font;
  color: inherit;
  font-size: 1.75 * $font-size-base;
  font-weight: bold;
  line-height: 1;
}

// Headings
.type-alpha,
.type-beta,
.type-gamma,
.type-delta,
.type-epsilon,
.type-zeta,
.type-omega {
  font-family: $omniva-headings-font;
  color: inherit;
  line-height: $omniva-headings-line-height;
}

.type-alpha, h1, .h1 {
  font-size: 2 * $font-size-base;
  font-weight: 500;
}

.type-beta, h2, .h2 {
  font-size: 1.75 * $font-size-base;
  font-weight: 300;
}

.type-gamma, h3, .h3 {
  font-size: 1.25 * $font-size-base;
  font-weight: 300;
}

.type-delta, h4, .h4 {
  font-size: 1.125 * $font-size-base;
  font-weight: 600;
}

.type-epsilon, h5, .h4 {
  font-size: $font-size-base;
  font-weight: 600;
}

.type-zeta, h6, .h6 {
  font-size: 0.875 * $font-size-base;
  font-weight: 600;
}

.type-omega {
  font-size: 0.75 * $font-size-base;
  font-weight: 600;
}

// Button

.btn {
  font-family: $omniva-input-font;
  font-size: 0.875rem;
}

//  Normal
body,
p,
.type-caption,
.type-legal {
  line-height: $omniva-line-height;
}

body, p {
  font-family: $omniva-headings-font;
  font-size: $font-size-base;
  font-feature-settings: "pnum", "lnum" on;
}

.type-caption {
  font-size: 0.75 * $font-size-base;
}

.type-legal {
  font-size: 0.6875 * $font-size-base;
}