@import 'assets/variables';

.create-subscription-form-bottom-bar {
  .btn-container {
    padding: 23px 20px;
    width: 550px;
    float: left;

    .btn {
      border: none;
      padding: 0.5rem 1.25rem;
      color: $omniva-color-black;
      border-radius: 50px;
      font-weight: 500;
      height: 38px;
      border: 0;
    }

    .btn-create {
      background-color: $omniva-color-button;
      width: 180px;
    }

    .btn-cancel {
      background-color: $omniva-color-white;
    }

    .btn-cancel-edit {
      background-color: $omniva-color-white;
      width: 92px;
      border: 1px solid red;
    }

    .btn-back {
      background-color: $omniva-color-white;
      width: 192px;
      border: 1px solid red;
    }

    .form-buttons {
      display: flex;
      justify-content: space-between;
    }

    .close-icon {
      font-size: small;
      fill: red;
    }

    .width-60 {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
  }
}

