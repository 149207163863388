@import 'assets/variables';

.new-publication {
  background-color: $omniva-color-button;
  border:none;
  padding: 0.5rem 1.25rem;
  color: $omniva-color-black;
  border-radius: 50px;
  font-weight: 400;
  position: relative;
  text-transform: none;
  height: 38px;
  width: 182px;

  .icon-btn {
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    padding-bottom: 3px;
  }
}
