@import 'variables';

@import 'bootstrap/scss/bootstrap';

@import 'bootstrap_custom_theme';

@import 'typography';


a {
  color: $omniva-color-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

hr {
  margin: 1rem 0;
  border-top: 1px solid $omniva-color-gray-light;
  border-bottom: none;
}

.error {
  color: $omniva-color-brand-accent;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

textarea {
  resize: none;
}
