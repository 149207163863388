@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.subscription-suspend-form {
  margin-left: 20px;
  margin-right: 20px;

  .field {
    background-color: $omniva-color-white;
    height: 38px;
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }

  .select-field {
    width: 100%;
  }

  .auto-suggest-field-container {
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }

  .dates {
    display: flex;
    justify-content: space-between;

    .date-picker {
      width: 193.5px;
    }

    .dash {
      margin-top: 7px;
    }
  }

  .hidden {
    display: none;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .submit-button {
      background-color: $omniva-color-button;
      color: $omniva-color-black;
      border:none;
      border-radius: 50px;
      padding: 8px 20px;
      width: 180px;
      height: 38px;
      font-weight: 400;
    }
  }

  .MuiInputBase-input {
    height: 4px;
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }

  .MuiInputLabel-root {
    font-family: $omniva-input-font;
    font-weight: 500;
    font-size: 14px;
  }
}