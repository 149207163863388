@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.filter-subscriptions {

  --field-height: 38px;
  --field-normal-width: 420px;
  --fields-line-width: 100%;

  container-type: inline-size;

  @media (min-width: 1500px) {
    --field-normal-width: 450px;
  }

  width: 100%;
  margin-top: 30px;

  .filter-form {
    @container (max-width: 1300px) {
      --field-normal-width: 350px;
    }

    margin-block: 10px;
    .first-line {
      display: flex;
      justify-content: space-between;
      width: var(--fields-line-width);
      margin-bottom: 20px;

      .subscription-code {
        .filter-field {
          width: var(--field-normal-width);
        }
      }

      .filter-field {
        width: var(--field-normal-width);
        .MuiInputBase-root {
          height: var(--field-height);
        }
      }
    }

    .second-line, .third-line {
      display: flex;
      justify-content: space-between;
      margin-block: 15px;

      .form-fields {
        width: var(--fields-line-width);
        display: flex;
        justify-content: space-between;

        .filter-field {
          width: var(--field-normal-width);

          .MuiInputBase-root {
            height: var(--field-height);
          }
        }

        .auto-suggest-product-container {
          .MuiFormControl-root {
            width: var(--field-normal-width);
          }
        }

        .status-filter, .registrant-filter {
          .MuiInputBase-root {
            height: var(--field-height);
            width: var(--field-normal-width);
          }
        }

        .include-expired {
          width: var(--field-normal-width);
        }

        .bottom {
          align-self: flex-end;
        }

        .registration-date-filter {
          width: var(--field-normal-width);

          label {
            align-self: self-end;
          }

          div.dates-container {
            display: flex;
            justify-content: space-between;
            width: 80%;

            .date-picker {
              width: 143.5px;
            }

            .dash {
              margin-top: 7px;
            }

            .MuiInputBase-root {
              height: var(--field-height);
            }
          }

        }
      }
    }


  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .search-button {
      background-color: #FFBC01;
      border-radius: 50px;
      padding: 8px 20px;
      width: 106px;
      height: var(--field-height);
      font-weight: 400;
    }

    .clear-button {
      background-color: #FFFFFF;
      border-radius: 50px;
      border: 1px solid #000000;
      padding: 8px 20px;
      width: 106px;
      height: var(--field-height);
      font-weight: 400;
    }
  }
}
