@import 'assets/variables';

.avatar-container {
  display: flex;
  align-items: center;
  gap: 0.5em;

  .user-name {
    color: $omniva-color-black;
    font-family: inherit;
  }

  .avatar {
    color: $omniva-color-black;
    background-color: rgb(244, 244, 244);
    font-family: inherit;
  }

  a {
    text-decoration: none;
  }
}
