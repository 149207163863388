@import 'assets/variables';

.form-label {
  margin-bottom: unset;
}

.form-label span {
  color: $omniva-color-black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-family: $omniva-headings-font;
}