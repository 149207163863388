@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@mixin custom-form-control-focus($color: $input-focus-border-color) {
  &:focus {
    border-color: $color;
    outline: 0;
    box-shadow: none;
  }
}

@mixin button-variant-shadow-styles($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  @include gradient-bg-enabled($background);
  box-shadow: $btn-box-shadow;

  @include hover {
    @include gradient-bg-enabled($hover-background);
  }

  &:focus,
  &.focus {
    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-image: none; // Remove the gradient for the pressed/active state

    &:focus {
      box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
    }
  }
}

@mixin gradient-bg-enabled($color) {
  background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x;
}

@each $color, $value in $theme-colors {
  .btn-shadow {
    &.btn-#{$color} {
      @include button-variant-shadow-styles($value, $value);
    }
  }
}

.form-control {
  @include custom-form-control-focus;
}

.badge-accent{
  @include badge-variant($omniva-color-brand-accent);
}
