@import 'assets/variables';

.create-subscription-form-cancel-dialog {
  .title {
    max-width: 100%;

    .span {
      font-family: $omniva-input-font;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.sub-btn-dialog-container {
  padding: 20px;

  .btn {
    border: none;
    padding: 0.5rem 1.25rem;
    color: $omniva-color-black;
    border-radius: 50px;
    font-weight: 500;
    height: 38px;
    border: 0;
  }

  .btn-yes {
    background-color: $omniva-color-button;
    width: 92px;
  }

  .btn-no {
    background-color: $omniva-color-white;
    width: 92px;
  }
}

