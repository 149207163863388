@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.workspace-view {
  height: 100%;
  --container-max-width: 1440px;

  .app-bar {
    min-width: 470px;

    .toolbar-body {
      display: flex;
      width: 100%
    }

    .main-menu {
      display: flex;
      align-items: center;
      color: $omniva-color-brand-primary;
      border-right: 1px solid lightgray;
      height: 64px;
      min-width: 160px;
      width: 160px;

      .logo {
        height: 24px;
        width: 100px;
        fill: $omniva-color-brand-primary;
        padding-left: 10px;
        padding-bottom: 4px;
      }
    }

    .secondary-menu {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .flex-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        .logo {
          fill: $omniva-color-brand-primary;
          height: 30px;
          width: 30px;
          padding-bottom: 4px;
        }

        .workspace-title {
          font-weight: 500;
          color: #333333;
          text-decoration: underline;
        }
      }

      .flex-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 2em;
      }
    }
  }

  .content {
    min-width: 295px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 100%;
    max-width: var(--container-max-width);
    margin-inline: auto;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    min-height: calc(100% - 64px);
  }

  .primary-view-enter {
    opacity: 0;
    transform:translateX(-100%);
  }
  .primary-view-enter-active {
    opacity: 1;
    transform:translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .primary-view-exit {
    opacity: 1;
    transform:translateX(0);
  }
  .primary-view-exit-active {
    opacity: 0;
    transform:translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
}
