
.title-line {
  padding-top: 40px;


  hr {
    border-top: 1px solid gray;
    margin-top: 8px;
  }

  h3 {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    width: 100px;
    display: inline;
  }

  .btn-add-new {
    float: right;
  }
}