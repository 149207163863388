@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.form-field-error-text {
  color: #f44336;
  margin-top: 3px;
  font-size: 0.75rem;
  font-family: Arial, Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}