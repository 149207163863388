@import 'assets/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.suspend-popover {

  .MuiPopover-paper  {
    max-height: 100%;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    margin-left: 20px;
    margin-right: 28px;
    margin-bottom: 20px;

    .close-button {
      all: unset;
      cursor: pointer;
      color: $omniva-color-brand-primary;
    }
  }

  .MuiPaper-root {
    width: 460px;
    height: 100vh;
    border-radius: 5px 0 0 5px;
    box-shadow: -16px 0 24px rgba(0, 0, 0, 0.04), -4px 0 8px rgba(0, 0, 0, 0.02);
  }

}